<template>
  <v-container>
    <v-card
      :loading="!records.length"
      class="mx-auto"
      max-width="374"
      elevation="0"
    >
      <v-card-text class="text-left">
<!--        Если дата недоступна для выбора, значит на эту дату свободных мест нет-->
        <div class="mt-2">
          <div class="calendar-event-day"></div> - доступно дневное время
        </div>
        <div class="mt-2">
          <div class="calendar-event-evening"></div> - доступно вечернее время после 17:00
        </div>
      </v-card-text>

      <div v-if="records.length">
        <v-row justify="center">
          <v-date-picker
            full-width
            v-model="date"
            locale="ru-RU"
            :allowed-dates="allowedDates"
            :events="functionEvents"
            first-day-of-week="1"
            color="deep-purple lighten-2"
          ></v-date-picker>
        </v-row>

        <v-divider class="mx-4 mt-4"></v-divider>

        <v-row justify="center" class="my-2">
          <v-card-text>
            <v-chip-group v-model="chip" active-class="deep-purple lighten-1 white--text" column>
              <v-row justify="center">
                <v-chip
                  class="elevation-3"
                  large
                  v-for="record in recordsForDay(date)"
                  :key="record._id"
                >{{ record.time }}
                </v-chip>
              </v-row>
            </v-chip-group>
          </v-card-text>
        </v-row>

        <v-divider class="mx-4 mb-4"></v-divider>

        <v-card-actions>
          <v-btn
            elevation="3"
            color="deep-purple lighten-2"
            :dark="!!this.time"
            @click="submitHandler"
            :disabled="!this.date || !this.time"
          >
            Продолжить
          </v-btn>
        </v-card-actions>
      </div>


    </v-card>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex"
import dateFilter from "@/filters/dateFilter";

export default {
  name: "ChooseDateTime",
  data: () => ({
    records: [],
    date: "",
    time: "",
    chip: undefined,
    length: undefined
  }),
  computed: {
    ...mapGetters(["ownerId", "services"]),
  },
  methods: {
    async loadRecordList() {
      const id = this.ownerId
      let json = JSON.stringify({id})

      const response = await fetch(`${this.$store.state.server}/record/load-window-list-for-client`, {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: json
      })

      json = await response.json()
      if (json.records) {
        this.records = json.records
        this.records.sort((r1, r2) => {
          if (r1.date < r2.date) return -1
          if (r1.date > r2.date) return 1
          if (r1.date === r2.date) {
            if (r1.time < r2.time) return -1
            if (r1.time > r2.time) return 1
            return 0
          }
        })
      } else {
        await this.$router.push("/")
      }

    },

    recordsForDay(date) {
      return this.records.filter(record => record.date === date)
    },

    firstOpenDay() {
      return Array.from(new Set(this.records.map(record => record.date))).sort()[0]
    },

    allowedDates(val) {
      return (this.recordsForDay(val).length > 0)
    },

    submitHandler() {
      if (!this.date || !this.time) {
        return
      }
      this.$store.commit("setDate", this.date)
      this.$store.commit("setTime", this.time)
      this.$router.push("/introduce_your_self")
    },

    functionEvents(date) {
      if (dateFilter(date, "ISO") <= dateFilter(Date.now(), "ISO")) return false
      const events = []
      this.records.forEach(record => {
        if (record.date === date) {
          if (record.time >= '17:00') events.push('#00f')
          else events.push('#07e3d2')
        }
      })
      if (events.length) return events
      return false
    },
  },

  async mounted() {
    if (!this.$store.getters.category || !this.$store.getters.services.length) {
      await this.$router.push("/")
      return
    }
    await this.loadRecordList()
    this.date = this.firstOpenDay()
  },

  watch: {
    chip: function (i) {
      if (i !== undefined) {
        this.time = this.recordsForDay(this.date)[this.chip].time
      } else {
        this.time = undefined
      }
    },

    date: async function (date) {
      this.chip = undefined
      if (await this.recordsForDay(date).length === 1) this.chip = 0
    }
  }
}
</script>

<style scoped>
  .calendar-event-day {
    border-radius: 50%;
    display: inline-flex;
    background-color: #07e3d2;
    height: 9px;
    width: 9px;
  }

  .calendar-event-evening {
    border-radius: 50%;
    display: inline-flex;
    background-color: #00f;
    height: 9px;
    width: 9px;
  }
</style>
